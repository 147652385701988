import React, { FunctionComponent, lazy } from 'react'
import ProtectedRoute from '@rikaiportal/shared/components/ProtectedRoute'
import FollowUpsPaths from '@rikaiportal/shared/modules/followUps/FollowUpsPaths'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import { ROUTE_CONFIG } from '../route.config'

const FollowUpIndex = lazy(() => delayedImport('followups/FollowUpIndex', ROUTE_CONFIG))
const FollowUpAddress = lazy(() => delayedImport('followups/FollowUpAddress', ROUTE_CONFIG))
const FollowUpSummary = lazy(() => delayedImport('followups/FollowUpSummary', ROUTE_CONFIG))
const FollowUpConfirmation = lazy(() =>
  delayedImport('followups/FollowUpConfirmation', ROUTE_CONFIG)
)

const FollowUpsRoutes: FunctionComponent = () => {
  return (
    <>
      <ProtectedRoute exact path={FollowUpsPaths.FOLLOW_UP_INDEX} component={FollowUpIndex} />
      <ProtectedRoute exact path={FollowUpsPaths.FOLLOW_UP_ADDRESS} component={FollowUpAddress} />
      <ProtectedRoute exact path={FollowUpsPaths.FOLLOW_UP_SUMMARY} component={FollowUpSummary} />
      <ProtectedRoute
        exact
        path={FollowUpsPaths.FOLLOW_UP_CONFIRMATION}
        component={FollowUpConfirmation}
      />
    </>
  )
}

export default FollowUpsRoutes
