import React, { FunctionComponent, lazy } from 'react'
import { Route } from 'react-router-dom'
import AuthPaths from '@rikaiportal/shared/modules/auth/AuthPaths'
import ProductsPaths from '@rikaiportal/shared/modules/products/ProductsPaths'
import ProtectedRoute from '@rikaiportal/shared/components/ProtectedRoute'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import { ROUTE_CONFIG } from '../route.config'

const ProductBuyingProcess = lazy(() =>
  delayedImport('products/ProductBuyingProcess', ROUTE_CONFIG)
)

const ProductAppointment = lazy(() => delayedImport('products/Appointment', ROUTE_CONFIG))

const ProductSelectionSummary = lazy(() =>
  delayedImport('products/ProductSelectionSummary', ROUTE_CONFIG)
)

const MedicalStatementConfirmation = lazy(() =>
  delayedImport('products/MedicalStatementConfirmation', ROUTE_CONFIG)
)

const ProductAddonsSelection = lazy(() =>
  delayedImport('products/ProductAddonsSelection', ROUTE_CONFIG)
)

const ProductsRoutes: FunctionComponent = () => {
  return (
    <>
      <Route exact path={ProductsPaths.PRODUCT_BUYING_PROCESS} component={ProductBuyingProcess} />
      <Route
        exact
        path={ProductsPaths.PRODUCT_ADDONS_SELECTION}
        component={ProductAddonsSelection}
      />
      <Route
        exact
        path={ProductsPaths.PARTNER_BRANCH_TIMESLOT_SELECTION}
        component={ProductAppointment}
      />
      <ProtectedRoute
        exact
        path={ProductsPaths.SUMMARY}
        component={ProductSelectionSummary}
        redirectURL={AuthPaths.AUTH_SIGNUP}
      />
      <Route
        exact
        path={ProductsPaths.MEDICAL_STATEMENT}
        component={MedicalStatementConfirmation}
      />
    </>
  )
}

export default ProductsRoutes
