import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import React, { FunctionComponent, lazy } from 'react'
import { Route } from 'react-router-dom'
import { ROUTE_CONFIG } from '../route.config'

const StoreMaintenance = lazy(() => delayedImport('maintenance/StoreMaintenance', ROUTE_CONFIG))

export enum StoreMaintenancePaths {
  STORE_MAINTENANCE = '/store-maintenance',
}

const StoreMaintenanceRoutes: FunctionComponent = () => {
  return (
    <>
      <Route path={StoreMaintenancePaths.STORE_MAINTENANCE} component={StoreMaintenance} />
    </>
  )
}

export default StoreMaintenanceRoutes
