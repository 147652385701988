import React, { FunctionComponent, lazy } from 'react'
import OrdersPaths from '@rikaiportal/shared/modules/orders/OrdersPaths'
import ProtectedRoute from '@rikaiportal/shared/components/ProtectedRoute'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import { ROUTE_CONFIG } from '../route.config'

const OrderIndex = lazy(() => delayedImport('orders/OrderIndex', ROUTE_CONFIG))
const OrderReschedule = lazy(() => delayedImport('orders/OrderReschedule', ROUTE_CONFIG))
const OrderRescheduleSummary = lazy(() =>
  delayedImport('orders/OrderRescheduleSummary', ROUTE_CONFIG)
)

const OrderConfirmation = lazy(() => delayedImport('orders/OrderConfirmation', ROUTE_CONFIG))
const OrderDetail = lazy(() => delayedImport('orders/OrderDetail', ROUTE_CONFIG))
const OrderAddonsSelection = lazy(() => delayedImport('orders/OrderAddonsSelection', ROUTE_CONFIG))
const OrderRescheduleFeeSummary = lazy(() =>
  delayedImport('orders/OrderRescheduleFeeSummary', ROUTE_CONFIG)
)

const OrderTermsAndConditionConfirmation = lazy(() =>
  delayedImport('orders/OrderTermsAndConditionConfirmation', ROUTE_CONFIG)
)

const OrdersRoutes: FunctionComponent = () => {
  return (
    <>
      <ProtectedRoute exact path={OrdersPaths.ORDER_INDEX} component={OrderIndex} />
      <ProtectedRoute exact path={OrdersPaths.ORDER_RESCHEDULE} component={OrderReschedule} />
      <ProtectedRoute
        exact
        path={OrdersPaths.ORDER_ADDONS_SELECTION}
        component={OrderAddonsSelection}
      />
      <ProtectedRoute
        exact
        path={OrdersPaths.ORDER_RESCHEDULE_COST_SUMMARY}
        component={OrderRescheduleFeeSummary}
      />
      <ProtectedRoute
        exact
        path={OrdersPaths.ORDER_RESCHEDULE_SUMMARY}
        component={OrderRescheduleSummary}
      />
      <ProtectedRoute
        exact
        path={OrdersPaths.ORDER_TERMS_AND_CONDITIONS}
        component={OrderTermsAndConditionConfirmation}
      />
      <ProtectedRoute exact path={OrdersPaths.ORDER_CONFIRMATION} component={OrderConfirmation} />
      <ProtectedRoute exact path={OrdersPaths.ORDER_DETAIL} component={OrderDetail} />
    </>
  )
}

export default OrdersRoutes
