import React, { FunctionComponent, lazy } from 'react'
import ProtectedRoute from '@rikaiportal/shared/components/ProtectedRoute'
import UsersPaths from '@rikaiportal/shared/modules/users/UsersPaths'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import { ROUTE_CONFIG } from '../route.config'

const UserProfile = lazy(() => delayedImport('users/UserProfile', ROUTE_CONFIG))
const CustomerDashboard = lazy(() => delayedImport('users/CustomerDashboard', ROUTE_CONFIG))

const UserRoutes: FunctionComponent = () => {
  return (
    <>
      <ProtectedRoute exact path={UsersPaths.USER_PROFILE} component={UserProfile} />
      <ProtectedRoute exact path={UsersPaths.CUSTOMER_DASHBOARD} component={CustomerDashboard} />
    </>
  )
}

export default UserRoutes
