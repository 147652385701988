import React, { FunctionComponent, lazy } from 'react'
import ProtectedRoute from '@rikaiportal/shared/components/ProtectedRoute'
import AppointmentsPaths from '@rikaiportal/shared/modules/appointments/AppointmentsPaths'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import { ROUTE_CONFIG } from '../route.config'

const AppointmentPreparation = lazy(() =>
  delayedImport('appointments/AppointmentPreparation', ROUTE_CONFIG)
)

const OrdersRoutes: FunctionComponent = () => {
  return (
    <>
      <ProtectedRoute
        exact
        path={AppointmentsPaths.APPOINTMENT_INDEX}
        component={AppointmentPreparation}
      />
    </>
  )
}

export default OrdersRoutes
