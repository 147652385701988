export enum OrdersPaths {
  ORDER_INDEX = '/orders/:orderId',
  ORDER_ADDONS_SELECTION = '/orders/:orderId/select-addons',
  ORDER_RESCHEDULE = '/orders/:orderId/reschedule',
  ORDER_RESCHEDULE_SUMMARY = '/orders/:orderId/reschedule/summary',
  ORDER_RESCHEDULE_COST_SUMMARY = '/orders/:orderId/reschedule/cost-summary',
  ORDER_CONFIRMATION = '/orders/:orderId/:rikaiTransactionNo/confirmation',
  ORDER_DETAIL = '/orders/:orderId/:rikaiTransactionNo/detail',
  ORDER_TERMS_AND_CONDITIONS = '/orders/:orderId/terms',
}

export default OrdersPaths
