import React, { FunctionComponent, lazy } from 'react'
import { Route } from 'react-router-dom'
import AuthPaths from '@rikaiportal/shared/modules/auth/AuthPaths'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import { ROUTE_CONFIG } from '../route.config'

const PhoneNumberInput = lazy(() => delayedImport('auth/PhoneNumberInput', ROUTE_CONFIG))
const VerifyNumber = lazy(() => delayedImport('auth/VerifyNumber', ROUTE_CONFIG))
const UserInfo = lazy(() => delayedImport('auth/UserInfo', ROUTE_CONFIG))
const UserAddress = lazy(() => delayedImport('auth/UserAddress', ROUTE_CONFIG))

const AuthRoutes: FunctionComponent = () => {
  return (
    <>
      <Route
        exact
        path={AuthPaths.AUTH_LOGIN}
        render={() => <PhoneNumberInput withSignUp={false} />}
      />
      <Route
        exact
        path={AuthPaths.AUTH_SIGNUP}
        render={() => <PhoneNumberInput withSignUp={true} />}
      />
      <Route exact path={AuthPaths.AUTH_VERIFY} component={VerifyNumber} />
      <Route exact path={AuthPaths.USER_INFO} component={UserInfo} />
      <Route exact path={AuthPaths.USER_ADDRESS} component={UserAddress} />
      <Route
        exact
        path={AuthPaths.AUTH_SIGNUP_VERIFY}
        render={() => <VerifyNumber withSignUp={true} />}
      />
    </>
  )
}

export default AuthRoutes
