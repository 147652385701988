import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import ScrollToTop from '@rikaiportal/shared/components/ScrollToTop'

import ProductsRoutes from './products/ProductsRoutes'
import SurveysRoutes from './surveys/SurveysRoutes'
import ResultsRoutes from './results/ResultsRoutes'
import OrdersRoutes from './orders/OrdersRoutes'
import AppointmentsRoutes from './appointments/AppointmentsRoutes'
import AuthRoutes from './auth/AuthRoutes'
import UserRoutes from './users/UserRoutes'
import StoreMaintenanceRoutes from './maintenance/StoreMaintenanceRoutes'
import ErrorBoundary from './components/ErrorBoundary'
import AdminPaths from '../../shared/modules/admin/AdminPaths'
import config from './config'
import FollowUpsRoutes from './followups/FollowUpsRoutes'
import { DelayedLoader } from '@rikaiportal/shared/components/DelayedLoader/DelayedLoader'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import { ROUTE_CONFIG } from './route.config'
import { Provider } from 'react-redux'
import { authenticationClient } from '@rikaiportal/shared/authentication-client/src/lib/client/auth-client'

import store from '@rikaiportal/shared/modules/store'
import { setupInterceptors } from '@rikaiportal/shared/api/interceptors.config'
import { AUTHENTICATION_TIMEOUT } from '@rikaiportal/shared/authentication-client/src/lib'

const Home = lazy(() => delayedImport('home', ROUTE_CONFIG))

setupInterceptors(store)
store.dispatch(authenticationClient.verifyAuth(AUTHENTICATION_TIMEOUT))

const App: React.FunctionComponent = () => {
  const queryParameter = new URLSearchParams(window.location.search)
  const voucherCode = queryParameter.get('vouchercode')
  if (voucherCode) {
    sessionStorage.setItem('voucherCode', voucherCode)
  }

  if (queryParameter.has('lang') && config.languageOptions.includes(queryParameter.get('lang'))) {
    sessionStorage.setItem('lang', queryParameter.get('lang'))
  }

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <Suspense fallback={<DelayedLoader delay={ROUTE_CONFIG.fallbackTime} />}>
              <SurveysRoutes />
              <ProductsRoutes />
              <ResultsRoutes />
              <OrdersRoutes />
              <FollowUpsRoutes />
              <AppointmentsRoutes />
              <AuthRoutes />
              <UserRoutes />
              <StoreMaintenanceRoutes />
              <Route
                exact
                path="/admin"
                render={() => <Redirect to={AdminPaths.ADMIN_RUNTIME} />}
              />
              <Route exact path="/" component={Home} />
            </Suspense>
          </Switch>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  )
}

export default App
