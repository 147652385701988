import React, { FunctionComponent, lazy } from 'react'
import ProtectedRoute from '@rikaiportal/shared/components/ProtectedRoute'
import SurveysPaths from '@rikaiportal/shared/modules/surveys/SurveysPaths'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import { ROUTE_CONFIG } from '../route.config'

const SurveyIndex = lazy(() => delayedImport('surveys/SurveyIndex', ROUTE_CONFIG))
const SurveyFinish = lazy(() => delayedImport('surveys/SurveyFinish', ROUTE_CONFIG))
const SurveyQuestion = lazy(() => delayedImport('surveys/SurveyQuestion', ROUTE_CONFIG))
const SurveyQuestionSummary = lazy(() =>
  delayedImport('surveys/SurveyQuestionSummary', ROUTE_CONFIG)
)
const ResearchApproval = lazy(() => delayedImport('surveys/ResearchApproval', ROUTE_CONFIG))

const SurveysRoutes: FunctionComponent = () => {
  return (
    <>
      <ProtectedRoute exact path={SurveysPaths.SURVEY_INDEX} component={SurveyIndex} />
      <ProtectedRoute exact path={SurveysPaths.SURVEY_QUESTION} component={SurveyQuestion} />
      <ProtectedRoute
        exact
        path={SurveysPaths.SURVEY_QUESTION_SUMMARY}
        component={SurveyQuestionSummary}
      />
      <ProtectedRoute exact path={SurveysPaths.SURVEY_FINISH} component={SurveyFinish} />
      <ProtectedRoute exact path={SurveysPaths.RESEARCH_APPROVAL} component={ResearchApproval} />
    </>
  )
}

export default SurveysRoutes
