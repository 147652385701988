import logdna from '@logdna/browser'
import config from './config'

const LOGDNA_INGESTION_KEY = config.services.logDnaKey

if (LOGDNA_INGESTION_KEY) {
  logdna.init(LOGDNA_INGESTION_KEY, {
    hostname: config.envName,
    app: 'rikaiportal_customer',
  })
}

export default LOGDNA_INGESTION_KEY ? logdna : console
