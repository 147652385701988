import React, { FunctionComponent, lazy } from 'react'
import ProtectedRoute from '@rikaiportal/shared/components/ProtectedRoute'
import ResultsPaths from '@rikaiportal/shared/modules/results/ResultsPaths'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import { ROUTE_CONFIG } from '../route.config'

const ResultsCommunication = lazy(() => delayedImport('results/ResultsCommunication', ROUTE_CONFIG))
const ResultsHistoryBloodValues = lazy(() =>
  delayedImport('results/ResultsHistoryBloodValues', ROUTE_CONFIG)
)
const ResultsHistoryOverview = lazy(() =>
  delayedImport('results/ResultsHistoryOverview', ROUTE_CONFIG)
)

const ResultsRoutes: FunctionComponent = () => {
  return (
    <>
      <ProtectedRoute
        exact
        path={ResultsPaths.RESULT_COMMUNICATION}
        component={ResultsCommunication}
      />
      <ProtectedRoute
        exact
        path={ResultsPaths.RESULT_HISTORY_OVERVIEW}
        component={ResultsHistoryOverview}
      />
      <ProtectedRoute
        exact
        path={ResultsPaths.RESULT_HISTORY_BLOODVALUE}
        component={ResultsHistoryBloodValues}
      />
    </>
  )
}

export default ResultsRoutes
